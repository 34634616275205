import { createEntityAdapter, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { loadProjectFolders } from 'scenes/ProjectBank/store/thunks/loadProjectFolders.thunk';
import { loadProjectBankFolderContents } from '../thunks/loadProjectBankFolderContents.thunk';
import {
  setFolderTree,
  setInitialProjectBankPath,
  setSelectedProjectBankFolder,
} from 'store/filepermissions/actions/filepermissions.actions';
import {
  closeProjectBank,
  closeProjectTreeFolders,
  openProjectTilaajavastuuFolder,
  openProjectTreeFolders,
  setSelectedTilaajavastuuBusinessId,
  setSortBy,
} from '../actions/project-bank.actions';
import { setAsDeletedProjectBankFilePermission } from '../thunks/setAsDeletedProjectBankFilePermission.thunk';
import { createProjectBankFile } from '../thunks/createProjectBankFile.thunk';
import { createProjectBankFolder } from '../thunks/createProjectBankFolder.thunk';
import { loadProjectTilaajavastuuDocuments } from '../thunks/loadProjectTilaajavastuuDocuments.thunk';
import { loadProjectAndSubProjectTilaajavastuuDocuments } from '../thunks/loadProjectAndSubProjectTilaajavastuuDocuments.thunk copy';

const filesFilePermissionsAdapter = createEntityAdapter();
const projectFolderFilePermissionsAdapter = createEntityAdapter();
const tilaajavastuuDocumentsAdapter = createEntityAdapter({
  selectId: (tilaajavastuuDocument) =>
    `${tilaajavastuuDocument.projectId}#${tilaajavastuuDocument.businessId}`,
});

const initialState = {
  projectTreeLoading: false,
  isFolderFilesLoading: false,
  filesFilePermissions: filesFilePermissionsAdapter.getInitialState(),
  projectFolderFilePermissions:
    projectFolderFilePermissionsAdapter.getInitialState(),
  tilaajavastuuDocuments: tilaajavastuuDocumentsAdapter.getInitialState(),
  selectedTilaajavastuuBusinessId: '',
  selectedFolderPath: '',
  selectedFolderId: '',
  initialProjectBankPath: '',
  folderTree: {},
  sortBy: '',
  isLoadingTilaajavastuuDocuments: false,
  selectedTilaajavastuuFolderProjectId: '',
  openedProjectTreeFolderIds: [],
};

export const projectBankSlice = createSlice({
  name: 'projectBank',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadProjectFolders.pending, (state, action) => {
        state.projectTreeLoading = true;
      })
      .addCase(loadProjectFolders.fulfilled, (state, action) => {
        projectFolderFilePermissionsAdapter.setMany(
          state.projectFolderFilePermissions,
          action.payload
        );
        state.projectTreeLoading = false;
      })
      .addCase(loadProjectFolders.rejected, (state, action) => {
        state.projectTreeLoading = false;
      })

      .addCase(loadProjectBankFolderContents.pending, (state, action) => {
        state.isFolderFilesLoading = true;
      })
      .addCase(loadProjectBankFolderContents.fulfilled, (state, action) => {
        filesFilePermissionsAdapter.setMany(
          state.filesFilePermissions,
          action.payload
        );
        state.isFolderFilesLoading = false;
      })
      .addCase(loadProjectBankFolderContents.rejected, (state, action) => {
        state.isFolderFilesLoading = false;
      })

      .addCase(setSelectedProjectBankFolder, (state, action) => {
        const { folderId, path } = action.payload;
        state.selectedFolderId = folderId;
        state.selectedFolderPath = path;
        if (folderId && !folderId.includes('tilaajavastuu')) {
          state.selectedTilaajavastuuBusinessId = '';
          state.selectedTilaajavastuuFolderProjectId = '';
        }
      })
      .addCase(setSortBy, (state, action) => {
        state.sortBy = action.payload;
      })
      .addCase(
        setAsDeletedProjectBankFilePermission.fulfilled,
        (state, action) => {
          if (action.payload.type === 'FOLDER') {
            projectFolderFilePermissionsAdapter.upsertOne(
              state.projectFolderFilePermissions,
              action.payload
            );
          } else {
            filesFilePermissionsAdapter.upsertOne(
              state.filesFilePermissions,
              action.payload
            );
          }
        }
      )
      .addCase(createProjectBankFolder.fulfilled, (state, action) => {
        projectFolderFilePermissionsAdapter.setOne(
          state.projectFolderFilePermissions,
          action.payload
        );
      })
      .addCase(createProjectBankFile.fulfilled, (state, action) => {
        filesFilePermissionsAdapter.setOne(
          state.filesFilePermissions,
          action.payload
        );
      })
      .addCase(setInitialProjectBankPath, (state, action) => {
        state.initialProjectBankPath = action.payload;
      })
      .addCase(setFolderTree, (state, action) => {
        state.folderTree = action.payload;
      })
      .addCase(closeProjectBank, () => ({
        ...initialState,
      }))
      .addCase(setSelectedTilaajavastuuBusinessId, (state, action) => {
        state.selectedTilaajavastuuBusinessId = action.payload;
      })
      .addCase(openProjectTilaajavastuuFolder, (state, action) => {
        state.selectedTilaajavastuuFolderProjectId = action.payload;
        state.selectedTilaajavastuuBusinessId = '';
      })
      .addCase(openProjectTreeFolders, (state, action) => {
        const openedFolderIds = action.payload;

        const currentOpenProjectTreeFolders = state.openedProjectTreeFolderIds;

        openedFolderIds.map((openedFolderId) => {
          if (!currentOpenProjectTreeFolders.includes(openedFolderId)) {
            state.openedProjectTreeFolderIds = [
              ...currentOpenProjectTreeFolders,
              openedFolderId,
            ];
          }
        });
      })
      .addCase(closeProjectTreeFolders, (state, action) => {
        const closedFolderIds = action.payload;

        const currentOpenProjectTreeFolders = state.openedProjectTreeFolderIds;

        closedFolderIds.forEach((folderId) => {
          state.openedProjectTreeFolderIds =
            currentOpenProjectTreeFolders.filter(
              (currentOpenFolderId) => currentOpenFolderId !== folderId
            );
        });
      })
      .addMatcher(
        isAnyOf(
          loadProjectTilaajavastuuDocuments.pending,
          loadProjectAndSubProjectTilaajavastuuDocuments.pending
        ),
        (state, action) => {
          state.isLoadingTilaajavastuuDocuments = true;
        }
      )
      .addMatcher(
        isAnyOf(
          loadProjectTilaajavastuuDocuments.fulfilled,
          loadProjectAndSubProjectTilaajavastuuDocuments.fulfilled
        ),
        (state, action) => {
          tilaajavastuuDocumentsAdapter.setMany(
            state.tilaajavastuuDocuments,
            action.payload
          );
          state.isLoadingTilaajavastuuDocuments = false;
        }
      )
      .addMatcher(
        isAnyOf(
          loadProjectTilaajavastuuDocuments.rejected,
          loadProjectAndSubProjectTilaajavastuuDocuments.rejected
        ),
        (state, action) => {
          state.isLoadingTilaajavastuuDocuments = false;
        }
      );
  },
});
