import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { selectUserAdminBusinessIds } from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';
import { generateReportFromRecords } from 'scenes/WorkDemo/utils/report-generation/generateReportFromRecords';
import { selectAssignmentsArray } from 'store/assignments/selectors/assignment.selectors';
import { selectProjectUsersArray } from 'store/projectUsers/selectors/projectUsers.selectors';
import { loadEmployeeConfigurationsInBusiness } from 'store/projectUsers/thunks/loadEmployeeConfigurationsInBusiness.thunk';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { selectSelectedRecordsIds } from 'store/records/selectors/selectedRecords.selectors';
import {
  selectIsRecordBasedReductionsActive,
  selectReportGeneratedFromMode,
} from 'store/reports/selectors/reports.selectors';
import {
  selectProjectsDataResourcesArrayWithEmployeeConfigurationInformation,
  selectWorkCalendarFilteredRecords,
  selectWorkCalendarProjectsArray,
} from '../../selectors/workSelectorsDemo';
import { selectRecordAllowancesGroupedByRecordId } from 'store/recordAllowances/selectors/record-and-employer-allowances.selectors';

export const generateReportPreviewTableFromSelectedRecords = createAsyncThunk(
  'workCalendar/generateReportPreviewTableFromSelectedRecords',
  async (dialogSettings, { getState, dispatch }) => {
    const selectedProjectId = selectSelectedProjectId(getState());

    const selectedRecordIds = selectSelectedRecordsIds(getState());

    const reportGeneratedFromMode = selectReportGeneratedFromMode(getState());

    const projects = selectWorkCalendarProjectsArray(getState());

    const allWorkCalendarFilteredRecords = selectWorkCalendarFilteredRecords(
      getState()
    );

    const assignments = selectAssignmentsArray(getState());

    const users = selectProjectUsersArray(getState());

    const isRecordBasedReductions = selectIsRecordBasedReductionsActive(
      getState()
    );

    const userAdminBusinessIds = selectUserAdminBusinessIds(getState());

    const recordAllowancesGroupedByRecordId =
      selectRecordAllowancesGroupedByRecordId(getState());

    const recordsToGenerateReportFrom =
      reportGeneratedFromMode === 'SELECTED'
        ? allWorkCalendarFilteredRecords.filter((record) =>
            selectedRecordIds.includes(record.id)
          )
        : allWorkCalendarFilteredRecords;

    const utcOffset = moment.parseZone(moment().format()).format('Z');

    const {
      selectedReportType,
      workStartTime,
      workEndTime,
      fromDate,
      toDate,
      language,
      filetype,
      workHourType,
      paidCoffeeBreakMinutes,
    } = dialogSettings;

    const generationParams = {
      fromDate: moment(fromDate).utcOffset(0).format(),
      toDate: moment(toDate).utcOffset(0).format(),
      selectedReportType,
      workStartTime,
      workEndTime,
      language,
      filetype,
      utcOffset,
      workHourType,
      isRecordBasedReductions,
      paidCoffeeBreakMinutes,
    };

    if (userAdminBusinessIds && userAdminBusinessIds.length > 0) {
      await dispatch(loadEmployeeConfigurationsInBusiness());
    }

    const resources =
      selectProjectsDataResourcesArrayWithEmployeeConfigurationInformation(
        getState()
      );

    const report = await generateReportFromRecords(
      recordsToGenerateReportFrom,
      resources,
      assignments,
      users,
      generationParams,
      projects,
      selectedProjectId,
      recordAllowancesGroupedByRecordId
    );

    return report;
  }
);
