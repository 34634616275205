import moment from 'moment';
import { RECORD_TYPE } from './enums';
const processes = require('./processes');

export const processData = (reportData, settings) => {
  /**
   * Used to trim reportData records time to work time range
   * @returns {Object} reportData
   */
  const trimRecordsTime = () => {
    const { workStartTime, workEndTime, records, utcOffset } = reportData;

    const offsetHourValue = parseInt(utcOffset.split(':')[0].slice(1));

    const filteredRecords = records.filter((record) => {
      const utcOffsetClockInAt = moment(record.clockInAt).utcOffset(0, false);

      const utcOffsetClockOutAt = moment(record.clockOutAt).utcOffset(0, false);

      const clockInMinutes =
        parseInt(utcOffsetClockInAt.format('HH')) * 60 +
        parseInt(utcOffsetClockInAt.format('mm'));

      const clockInAndClockOutMinuteDiff = utcOffsetClockOutAt.diff(
        utcOffsetClockInAt,
        'minutes',
        true
      );

      const clockOutTotalMinutes =
        clockInMinutes + clockInAndClockOutMinuteDiff;

      const workStartTimeMinutes =
        (parseInt(workStartTime.split(':')[0]) - offsetHourValue) * 60 +
        parseInt(workStartTime.split(':')[1]);

      const workEndTimeMinutes =
        (parseInt(workEndTime.split(':')[0]) - offsetHourValue) * 60 +
        parseInt(workEndTime.split(':')[1]);

      // If clockIn is after workEndTime or clockOut is before workStartTime, do nothing and return
      if (
        clockInMinutes < workEndTimeMinutes &&
        clockOutTotalMinutes > workStartTimeMinutes
      )
        return record;
    });

    filteredRecords.map((record) => {
      const utcOffsetClockInAt = moment(record.clockInAt).utcOffset(0, false);

      const utcOffsetClockOutAt = moment(record.clockOutAt).utcOffset(0, false);

      const clockInMinutes =
        parseInt(utcOffsetClockInAt.format('HH')) * 60 +
        parseInt(utcOffsetClockInAt.format('mm'));
      const clockOutMinutes =
        parseInt(utcOffsetClockOutAt.format('HH')) * 60 +
        parseInt(utcOffsetClockOutAt.format('mm'));

      const workStartTimeMinutes =
        (parseInt(workStartTime.split(':')[0]) - offsetHourValue) * 60 +
        parseInt(workStartTime.split(':')[1]);

      const workEndTimeMinutes =
        (parseInt(workEndTime.split(':')[0]) - offsetHourValue) * 60 +
        parseInt(workEndTime.split(':')[1]);

      if (clockInMinutes < workStartTimeMinutes) {
        record.clockInAt = moment(record.clockInAt)
          .utcOffset(0, false)
          .startOf('day')
          .add(workStartTimeMinutes, 'minutes')
          .toISOString();
      }
      if (clockOutMinutes > workEndTimeMinutes) {
        record.clockOutAt = moment(record.clockOutAt)
          .utcOffset(0, false)
          .startOf('day')
          .add(workEndTimeMinutes, 'minutes')
          .toISOString();
      }
      return record;
    });

    reportData.records = filteredRecords;
    return reportData;
  };

  const initDays = () => {
    const fromDate = moment(reportData.fromDate).utcOffset(
      reportData.utcOffset,
      false
    );
    const toDate = moment(reportData.toDate).utcOffset(
      reportData.utcOffset,
      false
    );

    let days = {};
    let daysTypes = {};
    let i = fromDate;
    while (i.format('YYYY-MM-DD') <= toDate.format('YYYY-MM-DD')) {
      const day = i.year() + '-' + (i.month() + 1) + '-' + i.date();
      if (!(day in days)) {
        days[day] = {};
        daysTypes[day] = {};
        daysTypes[day][RECORD_TYPE.WORK] = {};
        daysTypes[day][RECORD_TYPE.PROJECTWORK] = {};
        daysTypes[day][RECORD_TYPE.ABSENCE] = {};
        daysTypes[day][RECORD_TYPE.ABSENCE_VACATION_PAID] = {};
        daysTypes[day][RECORD_TYPE.ABSENCE_SICK_LEAVE] = {};
        daysTypes[day][RECORD_TYPE.BREAK] = {};
        daysTypes[day][RECORD_TYPE.BREAK_COFFEE] = {};
        daysTypes[day][RECORD_TYPE.BREAK_MEAL] = {};
        daysTypes[day][RECORD_TYPE.SHIFT] = {};
        daysTypes[day][RECORD_TYPE.RIDE] = {};
        daysTypes[day][RECORD_TYPE.BENEFIT] = {};
        daysTypes[day][RECORD_TYPE.ITEM] = {};
        daysTypes[day][RECORD_TYPE.NEGATIVE] = {};
      }
      i.add(1, 'days');
    }
    reportData.days = days;
    reportData.daysTypes = daysTypes;

    return reportData;
  };

  const baseHours = () => {
    const records = reportData.records;
    let days = reportData.days;
    let daysTypes = reportData.daysTypes;

    const { recordAllowancesGroupedByRecordId } = reportData;

    const recordsWithExistingResource = records.filter(
      (record) => record.resource
    );

    let sortedRecords = [];

    try {
      recordsWithExistingResource.sort((a, b) =>
        a.clockInAt.localeCompare(b.clockInAt)
      );
      if (settings.lastNameFirst) {
        sortedRecords = recordsWithExistingResource.sort((a, b) =>
          a.resource.lastName.localeCompare(
            b.resource.lastName,
            settings.language,
            { sensitivity: 'base' }
          )
        );
      } else {
        sortedRecords = recordsWithExistingResource.sort((a, b) =>
          a.resource.firstName.localeCompare(
            b.resource.firstName,
            settings.language,
            { sensitivity: 'base' }
          )
        );
      }
    } catch (err) {
      console.log('ERROR 1');
    }

    let resources = {};

    sortedRecords.forEach((record) => {
      let clockOut = null;
      if (record.clockOutAt) {
        clockOut = new Date(record.clockOutAt);
      }

      if (clockOut) {
        let endDay = moment(record.clockOutAt)
          .utc()
          .utcOffset(reportData.utcOffset, false);
        let i = moment(record.clockInAt)
          .utc()
          .utcOffset(reportData.utcOffset, false);

        while (i.format('YYYY-MM-DD') <= endDay.format('YYYY-MM-DD')) {
          const day = i.year() + '-' + (i.month() + 1) + '-' + i.date();

          if (Object.keys(days).includes(day)) {
            if (!(day in days)) {
              days[day] = {};
            }
            const resource = record.resource;
            if (!(resource.user in days[day])) {
              days[day][resource.user] = {
                resource,
                hours: 0,
                reduction: 0,
              };
            }
            if (daysTypes[day][record.type] !== undefined) {
              if (!(resource.user in daysTypes[day][record.type])) {
                daysTypes[day][record.type][resource.user] = {
                  value: 0,
                  reduction: 0,
                };
              }
            }
            if (!(resource.user in resources)) {
              resources[resource.user] = {
                resource,
                totalHours: 0,
                days: {},
                daysTypes: {},
                totals: {},
                reductions: {},
                automaticReductions: 0,
                additionalSalaries: {},
              };
              resources[resource.user].totals[RECORD_TYPE.ABSENCE] = 0;
              resources[resource.user].totals[
                RECORD_TYPE.ABSENCE_SICK_LEAVE
              ] = 0;
              resources[resource.user].totals[
                RECORD_TYPE.ABSENCE_VACATION_PAID
              ] = 0;
              resources[resource.user].totals[RECORD_TYPE.BENEFIT] = 0;
              resources[resource.user].totals[RECORD_TYPE.BREAK] = 0;
              resources[resource.user].totals[RECORD_TYPE.BREAK_COFFEE] = 0;
              resources[resource.user].totals[RECORD_TYPE.BREAK_MEAL] = 0;
              resources[resource.user].totals[RECORD_TYPE.ITEM] = 0;
              resources[resource.user].totals[RECORD_TYPE.RIDE] = 0;
              resources[resource.user].totals[RECORD_TYPE.NEGATIVE] = 0;
              resources[resource.user].totals[RECORD_TYPE.SHIFT] = 0;
              resources[resource.user].totals[RECORD_TYPE.WORK] = 0;
              resources[resource.user].totals[RECORD_TYPE.PROJECTWORK] = 0;
              resources[resource.user].reductions[RECORD_TYPE.WORK] = 0;
              resources[resource.user].reductions[RECORD_TYPE.PROJECTWORK] = 0;
            }
            if (!(day in resources[resource.user].days)) {
              resources[resource.user].days[day] = {
                hours: 0,
                reduction: 0,
              };
            }
            if (!(day in resources[resource.user].daysTypes)) {
              resources[resource.user].daysTypes[day] = {};
            }
            if (!(record.type in resources[resource.user].daysTypes[day])) {
              resources[resource.user].daysTypes[day][record.type] = {
                value: 0,
                reduction: 0,
              };
            }

            const co = moment.min(
              moment(record.clockOutAt),
              moment(i).set({
                hours: 23,
                minute: 59,
                second: 59,
                millisecond: 999,
              })
            );
            const ci = moment.max(
              moment(record.clockInAt),
              moment(i).set({
                hours: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
            );
            const hours = co.diff(ci, 'hours', true);
            days[day][resource.user].hours += hours;
            if (days[day][resource.user].hours > 6) {
              days[day][resource.user].reduction = 0.5;
            }

            daysTypes[day][record.type][resource.user].value += hours;
            if (
              record.type === RECORD_TYPE.WORK ||
              record.type === RECORD_TYPE.PROJECTWORK
            ) {
              if (daysTypes[day][record.type][resource.user].value > 6) {
                if (!daysTypes[day][record.type][resource.user].reduction) {
                  daysTypes[day][record.type][resource.user].reduction = 0.5;
                  resources[resource.user].automaticReductions += 0.5;
                }
              }

              const recordAllowances =
                recordAllowancesGroupedByRecordId[record.id];

              if (recordAllowances && recordAllowances.length > 0) {
                recordAllowances.forEach((recordAllowance) => {
                  const existingAddedAdditionalSalaryData =
                    resources[resource.user].additionalSalaries[
                      recordAllowance.salaryType
                    ];
                  const date = moment(record.clockInAt).format('D.M.');

                  if (existingAddedAdditionalSalaryData) {
                    existingAddedAdditionalSalaryData.unitCount += Number(
                      recordAllowance.unitCount
                    );
                    existingAddedAdditionalSalaryData.definedDates.push(date);
                    existingAddedAdditionalSalaryData.dayUnitCount[date] =
                      Number(recordAllowance.unitCount);
                  } else {
                    resources[resource.user].additionalSalaries[
                      recordAllowance.salaryType
                    ] = {
                      unitCount: Number(recordAllowance.unitCount),
                      definedDates: [date],
                      salaryType: recordAllowance.salaryType,
                      dayUnitCount: {
                        [date]: Number(recordAllowance.unitCount),
                      },
                    };
                  }
                });
              }
            } else if (
              record.type === RECORD_TYPE.ABSENCE_SICK_LEAVE ||
              record.type === RECORD_TYPE.ABSENCE_VACATION_PAID
            ) {
              const existingAddedAdditionalSalaryData =
                resources[resource.user].additionalSalaries[record.type];
              const date = moment(record.clockInAt).format('D.M.');
              if (existingAddedAdditionalSalaryData) {
                existingAddedAdditionalSalaryData.unitCount += 1;
                existingAddedAdditionalSalaryData.definedDates.push(date);
              } else {
                resources[resource.user].additionalSalaries[record.type] = {
                  unitCount: 1,
                  definedDates: [date],
                  salaryType:
                    record.type === RECORD_TYPE.ABSENCE_SICK_LEAVE
                      ? 'SICK_LEAVE_PAY'
                      : 'HOLIDAY_PAY',
                };
              }
            }
            resources[resource.user].totalHours += hours;
            resources[resource.user].totals[record.type] += hours;
          }
          i.add(1, 'days');
        }
      }
    });
    reportData['resources'] = resources;
    return reportData;
  };

  const runProcesses = () => {
    settings.processes.forEach((process) => {
      reportData = processes[process.type](reportData, process);
    });
    return reportData;
  };

  reportData = trimRecordsTime();
  reportData = initDays();
  reportData = baseHours();
  reportData = runProcesses();

  return reportData;
};
