import { createSelector } from '@reduxjs/toolkit';
import { selectAllUsersPermissions } from '../slices/permissionsSlice';

import { groupBy, sortBy, uniqBy } from 'lodash-es';
import {
  EMPLOYEE_DEFAULT_ROLE_ITEM,
  RESIDENT_DEFAULT_ROLE_ITEM,
} from 'scenes/Permissions/constants/lixani-default-project-roles';
import { findResourcePermissionsFromParentProjects } from 'scenes/Permissions/utils/findPermissionsFromParentProjects.util';
import { overrideRolePermissionsWithUserPermissions } from 'scenes/Permissions/utils/overrideRolePermissionsWithUserPermissions.util';
import { returnResourcesFromSubProjects } from 'scenes/Permissions/utils/returnResourcesFromSubProjects.util';
import { returnTogglableDefaultPermissions } from 'scenes/Permissions/utils/returnTogglableDefaultPermissions.util';
import {
  selectProjects,
  selectProjectsArray,
  selectSelectedProject,
  selectSelectedProjectId,
} from 'store/projects/selectors/projectsSelectors';
import { selectAllResources } from 'store/resources/resourceSliceSelectors';
import { selectCurrentUser } from 'store/selectors/root.selectors';
import { selectLoggedInUserId } from 'store/user/selectors.js/user.selectors';
import i18n from 'i18next';

const selectPermissionsState = (state) => state.permissions;

export const selectAdminEntities = createSelector(
  [selectPermissionsState],
  (state) => state.admins.entities
);

const selectRolesEntities = createSelector(
  [selectPermissionsState],
  (state) => state.roles.entities
);

export const selectIsLoadingChangeHistory = createSelector(
  [selectPermissionsState],
  (state) => state.isLoadingChangeHistory
);

export const selectChangeHistoryItemsEntities = createSelector(
  [selectPermissionsState],
  (state) => state.changeHistoryItems.entities
);
export const selectChangeHistoryItemsArray = createSelector(
  [selectChangeHistoryItemsEntities],
  (entities) => Object.values(entities)
);

export const selectChangeHistoryItemsArrayWithJoinedData = createSelector(
  [selectChangeHistoryItemsArray, selectAllResources],
  (historyItems, resources) =>
    historyItems.map((historyItem) => {
      const historyItemUserResource = resources.find(
        (resource) => resource.user === historyItem.userId
      );

      return {
        ...historyItem,
        userName: `${historyItemUserResource?.firstName} ${historyItemUserResource.lastName}`,
      };
    })
);

export const selectRolesArray = createSelector([selectRolesEntities], (roles) =>
  Object.values(roles)
);

export const selectAssignedDefaultRolesEntities = createSelector(
  [selectPermissionsState],
  (state) => state.assignedDefaultRoles.entities
);

export const selectAssignedDefaultRolesArray = createSelector(
  [selectAssignedDefaultRolesEntities],
  (defaultRoles) => Object.values(defaultRoles)
);

export const selectSelectedProjectAssignedDefaultRoles = createSelector(
  [selectAssignedDefaultRolesArray, selectSelectedProjectId],
  (defaultRoles, projectId) => {
    return defaultRoles.filter(
      (defaultRole) => defaultRole.projectId === projectId
    );
  }
);

export const selectSelectedUsersToUpdatePermissions = createSelector(
  [selectPermissionsState],
  (state) => state.selectedUsersToUpdatePermissions
);

export const selectUserSearchFilter = createSelector(
  [selectPermissionsState],
  (state) => state.userSearchFilter
);

export const selectProjectRolePermissionModifications = createSelector(
  [selectPermissionsState],
  (state) => state.projectRolePermissionModifications
);

export const selectAssignedDefaultRolesModifications = createSelector(
  [selectPermissionsState],
  (state) => state.assignedDefaultRoleModifications
);

export const selectUserPermissionModifications = createSelector(
  [selectPermissionsState],
  (state) => state.userPermissionModifications
);

export const selectUserRoleModifications = createSelector(
  [selectPermissionsState],
  (state) => state.userRoleModifications
);

export const selectIsDeletingProjectRole = createSelector(
  [selectPermissionsState],
  (state) => state.isDeletingProjectRole
);

export const selectIsCreatingNewRole = createSelector(
  [selectPermissionsState],
  (state) => state.isCreatingNewRole
);

export const selectIsUpdatingPermissionsOfUser = createSelector(
  [selectPermissionsState],
  (state) => state.isUpdatingPermissionsOfUser
);

export const selectIsUpdatingUserRoles = createSelector(
  [selectPermissionsState],
  (state) => state.isUpdatingUserRoles
);

export const selectUsersGroupedBy = createSelector(
  [selectPermissionsState],
  (state) => state.usersGroupedBy
);

export const selectSelectedUsersToAssignRole = createSelector(
  [selectPermissionsState],
  (state) => state.selectedUsersToAssignRole
);

export const selectProjectRoles = createSelector(
  [selectRolesArray, (_, args) => args],
  (allRoles, args) => {
    const { projectId } = args;

    const projectRoles = allRoles.filter(
      (role) => role.projectId === projectId
    );

    return Object.values(projectRoles);
  }
);

const selectProjectRolesArrayWithoutDeleted = createSelector(
  [selectRolesArray],
  (roles) => roles.filter((role) => !role.deletedAt)
);

export const selectSelectedProjectRoles = createSelector(
  [selectProjectRolesArrayWithoutDeleted, selectSelectedProjectId],
  (roles, selectedProjectId) =>
    roles.filter((role) => role.projectId === selectedProjectId)
);

export const selectSelectedProjectEmployeeDefaultRole = createSelector(
  [
    selectSelectedProjectAssignedDefaultRoles,
    selectSelectedProjectRoles,
    selectAssignedDefaultRolesModifications,
  ],
  (projectDefaultRoles, projectRoles, assignedDefaultRolesModifications) => {
    const assignedDefaultEmployeeRole = projectDefaultRoles.find(
      (role) => role.SK === 'DEFAULT_ROLE_EMPLOYEE'
    );

    const assignedEmployeeDefaultRoleModification = Object.values(
      assignedDefaultRolesModifications
    ).find(
      (defaultRoleModification) =>
        defaultRoleModification.SK === 'DEFAULT_ROLE_EMPLOYEE'
    );

    if (assignedEmployeeDefaultRoleModification) {
      const assignedRole = projectRoles.find(
        (role) => role.roleId === assignedEmployeeDefaultRoleModification.roleId
      );
      return assignedRole;
    } else if (assignedDefaultEmployeeRole) {
      const currentAssignedDefaultEmployeeRoleId = assignedDefaultEmployeeRole
        ? assignedDefaultEmployeeRole.roleId
        : undefined;

      const assignedRole = projectRoles.find(
        (role) => role.roleId === currentAssignedDefaultEmployeeRoleId
      );

      return assignedRole;
    } else {
      return undefined;
    }
  }
);

export const selectSelectedProjectExternalDefaultRole = createSelector(
  [
    selectSelectedProjectAssignedDefaultRoles,
    selectSelectedProjectRoles,
    selectAssignedDefaultRolesModifications,
  ],
  (projectDefaultRoles, projectRoles, assignedDefaultRolesModifications) => {
    const assignedDefaultExternalRole = projectDefaultRoles.find(
      (role) => role.SK === 'DEFAULT_ROLE_EXTERNAL'
    );

    const assignedExternalDefaultRoleModification = Object.values(
      assignedDefaultRolesModifications
    ).find(
      (defaultRoleModification) =>
        defaultRoleModification.SK === 'DEFAULT_ROLE_EXTERNAL'
    );

    if (assignedExternalDefaultRoleModification) {
      const assignedRole = projectRoles.find(
        (role) => role.roleId === assignedExternalDefaultRoleModification.roleId
      );
      return assignedRole;
    } else if (assignedDefaultExternalRole) {
      const currentAssignedDefaultExternalRoleId = assignedDefaultExternalRole
        ? assignedDefaultExternalRole.roleId
        : undefined;

      const assignedRole = projectRoles.find(
        (role) => role.roleId === currentAssignedDefaultExternalRoleId
      );

      return assignedRole;
    } else {
      return undefined;
    }
  }
);

export const selectSelectedProjectRolesWithModifications = createSelector(
  [selectSelectedProjectRoles, selectProjectRolePermissionModifications],
  (roles, roleModifications) =>
    roles.map((role) => {
      const currentRoleModifications = roleModifications[role.roleId];

      if (currentRoleModifications) {
        return {
          ...role,
          permissions: { ...role.permissions, ...currentRoleModifications },
        };
      } else {
        return role;
      }
    })
);

export const selectResourcesWithoutCurrentUserResources = createSelector(
  [selectAllResources, selectCurrentUser],
  (resources, currentUser) => {
    const filteredResources = resources.filter(
      (resource) => resource.user !== currentUser.id
    );

    const sortedResources = sortBy(filteredResources, ['firstName']);
    return sortedResources;
  }
);

export const selectUserPermissionsWithModifications = createSelector(
  [selectAllUsersPermissions, selectUserRoleModifications],
  (userPermissions, userRoleModifications) =>
    userPermissions.map((singleUserProjectPermissions) => {
      const { userId, projectId } = singleUserProjectPermissions;
      const currentUserRoleModifications =
        userRoleModifications[`${userId}#${projectId}`];

      if (currentUserRoleModifications) {
        return {
          ...singleUserProjectPermissions,
          roleId: currentUserRoleModifications.roleId,
        };
      } else {
        return singleUserProjectPermissions;
      }
    })
);

export const selectPermissionsOfProjectResources = createSelector(
  [
    selectAdminEntities,
    selectRolesArray,
    selectAllResources,
    selectUserPermissionsWithModifications,
    selectSelectedProjectId,
    selectSelectedProject,
    selectProjectsArray,
    selectProjects,
    selectUserRoleModifications,
  ],
  (
    adminEntities,
    allRoles,
    allResources,
    usersProjectPermissions,
    selectedProjectId,
    selectedProject,
    projects,
    projectsMap,
    userRoleModifications
  ) => {
    const sortedResources = sortBy(allResources, ['firstName']);

    let projectResources = [];

    if (selectedProject.businessTypeEnum === 'EMPLOYER') {
      const subProjectResources = returnResourcesFromSubProjects(
        projects,
        selectedProjectId,
        sortedResources
      );

      const employerProjectResources = (projectResources =
        sortedResources.filter(
          (resource) => resource.project === selectedProjectId
        ));
      projectResources = [...subProjectResources, ...employerProjectResources];
    } else {
      projectResources = sortedResources.filter(
        (resource) => resource.project === selectedProjectId
      );
    }

    const uniqueProjectResources = uniqBy(projectResources, 'user');

    const resourcesWithPermissions = uniqueProjectResources.map((resource) => {
      let foundUserPermissions = usersProjectPermissions.find(
        (userProjectPermissions) =>
          userProjectPermissions.userId === resource.user &&
          userProjectPermissions.projectId === selectedProjectId
      );

      if (!foundUserPermissions) {
        if (userRoleModifications[`${resource.user}#${selectedProjectId}`]) {
          foundUserPermissions =
            userRoleModifications[`${resource.user}#${selectedProjectId}`];
        } else {
          foundUserPermissions = findResourcePermissionsFromParentProjects(
            selectedProject.parent,
            projectsMap,
            usersProjectPermissions,
            resource
          );
        }
      }

      let userRoleId;

      let rolePermissions;
      let roleName;

      if (foundUserPermissions) {
        userRoleId = foundUserPermissions.roleId;

        const projectRoles = allRoles.filter(
          (role) => role.projectId === foundUserPermissions.projectId
        );

        if (userRoleId) {
          const userRole = projectRoles.find(
            (role) => role.roleId === userRoleId
          );
          if (userRole) {
            rolePermissions = userRole.permissions;
            roleName = userRole.roleName;
          }
        }
      }

      const resourceDefaultPermissions =
        !resource.employmentType || resource.employmentType === 'EMPLOYEE'
          ? EMPLOYEE_DEFAULT_ROLE_ITEM.permissions
          : RESIDENT_DEFAULT_ROLE_ITEM.permissions;

      const appDefaultPermissions = returnTogglableDefaultPermissions();

      const rolePermissionsWithUserPermissions =
        overrideRolePermissionsWithUserPermissions(
          appDefaultPermissions,
          resourceDefaultPermissions,
          rolePermissions,
          foundUserPermissions.permissions
        );

      const employerData = projectsMap[resource.employer];

      resource = {
        ...resource,
        employerData: employerData,
        permissions: {
          roleName,
          userRoleId,
          rolePermissions,
          userPermissions: rolePermissionsWithUserPermissions,
          inheritedProject: foundUserPermissions.inheritedProject,
          isProjectOwner: foundUserPermissions.isProjectOwner,
          isAdmin: adminEntities[
            `${resource.user}#${selectedProject.business_id}`
          ]
            ? true
            : false,
        },
      };

      return resource;
    });

    return resourcesWithPermissions;
  }
);

const selectSearchFilteredPermissionsResources = createSelector(
  [selectPermissionsOfProjectResources, selectUserSearchFilter],
  (permissionsResources, searchText) => {
    if (!searchText) {
      return permissionsResources;
    }

    const filteredResources = permissionsResources.filter((resource) => {
      const resourceEmployer = resource.employerData?.name;
      const business_id = resource.employerData?.business_id || '';

      const searchableValue = `${resource.firstName} ${resource.lastName} ${resourceEmployer} ${business_id}`;

      const splitValue = searchText.split(' ');
      const splitSearchableValue = searchableValue.split(' ');

      const searchResult = splitValue.every((splitItem) =>
        splitSearchableValue.some((data) =>
          data.toLowerCase().includes(splitItem)
        )
      );

      if (searchResult) {
        return resource;
      }
    });

    return filteredResources;
  }
);

const returnEmployerGroupedResources = (resources) => {
  const groupedByEmployer = groupBy(resources, 'employerData.business_id');

  groupedByEmployer['RESIDENTS'] = [];
  groupedByEmployer['MyLixani'] = [];

  const undefinedEmployerResources = groupedByEmployer['undefined']
    ? groupedByEmployer['undefined']
    : [];

  undefinedEmployerResources.forEach((resource) => {
    if (resource.employmentType === 'RESIDENT') {
      groupedByEmployer['RESIDENTS'].push(resource);
    } else {
      groupedByEmployer['MyLixani'].push(resource);
    }
  });

  delete groupedByEmployer['undefined'];

  const returnSortingName = (name) => {
    if (name === 'RESIDENTS') {
      return 'zzzz';
    } else if (name === 'MyLixani') {
      return 'yyyy';
    } else {
      return name.toLowerCase();
    }
  };

  const groupedByEmployerArray = Object.entries(groupedByEmployer).map(
    ([key, value]) => {
      const resources = value;

      const resourceWithFoundEmployerWithName = resources.find(
        (resource) => resource.employerData && resource.employerData.name
      );

      const resourceWithFoundBusinessId = resources.find(
        (resource) => resource.employerData && resource.employerData.business_id
      );

      const isResidents = key === 'RESIDENTS';
      const isMyLixani = key === 'MyLixani';
      const name = isResidents
        ? i18n.t('calendar.residents')
        : isMyLixani
        ? 'MyLixani'
        : resourceWithFoundEmployerWithName.employerData.name;

      const sortingName =
        key !== 'RESIDENTS' && key !== 'MyLixani'
          ? returnSortingName(name)
          : returnSortingName(key);

      const groupIndex =
        key !== 'RESIDENTS' && key !== 'MyLixani'
          ? resourceWithFoundBusinessId?.employerData.business_id
          : key;

      return {
        name: name,
        sortingName,
        business_id: resourceWithFoundBusinessId?.employerData.business_id,
        employeeResources: resources,
        groupIndex,
      };
    }
  );

  const sorted = sortBy(groupedByEmployerArray, ['sortingName']);

  return sorted;
};

export const selectResourcesGroupedByEmployer = createSelector(
  [selectSearchFilteredPermissionsResources],
  (resources) => {
    return returnEmployerGroupedResources(resources);
  }
);

export const selectResourcesGroupedByRole = createSelector(
  [selectSearchFilteredPermissionsResources],
  (resources) => {
    let adminResources = [];
    let nonAdminResources = [];
    resources.forEach((resource) => {
      if (resource.permissions.isAdmin) {
        adminResources.push(resource);
      } else {
        nonAdminResources.push(resource);
      }
    });

    const groupedByRole = groupBy(nonAdminResources, 'permissions.roleName');

    groupedByRole['ADMIN'] = adminResources;

    const groupedByRoleArray = Object.entries(groupedByRole).map(
      ([key, value]) => {
        const resourcesGroupedByEmployer =
          returnEmployerGroupedResources(value);

        return {
          name: key !== 'undefined' ? key : i18n.t('permissions.no_role'),
          businessResources: resourcesGroupedByEmployer,
          sortingName: key !== 'undefined' ? key.toLowerCase() : 'zzzz',
          groupIndex: key,
        };
      }
    );

    const sorted = sortBy(groupedByRoleArray, ['sortingName']);

    return sorted;
  }
);

export const selectPermissionsViewGroupedResources = createSelector(
  [
    selectResourcesGroupedByEmployer,
    selectResourcesGroupedByRole,
    selectUsersGroupedBy,
  ],
  (resourcesGroupedByEmployer, resourcesGroupedByRole, usersGroupedBy) =>
    usersGroupedBy === 'business'
      ? resourcesGroupedByEmployer
      : resourcesGroupedByRole
);

export const selectIsPermissionsLoading = createSelector(
  [selectPermissionsState],
  (state) => state.loading
);
export const selectIsAdminsLoaded = createSelector(
  [selectPermissionsState],
  (state) => state.adminsLoaded
);

export const selectIsAdminOfBusiness = createSelector(
  [selectAdminEntities, selectLoggedInUserId, selectSelectedProject],
  (adminEntities, loggedInUserId, selectedProject) =>
    adminEntities[`${loggedInUserId}#${selectedProject.business_id}`]
      ? true
      : false
);

export const selectUserRoleModificationChanges = createSelector(
  [selectUserRoleModifications, selectAllResources, selectSelectedProjectRoles],
  (roleModifications, resources, projectRoles) => {
    const changes = [];

    Object.values(roleModifications).forEach((roleModification) => {
      const userResource = resources.find(
        (resource) => resource.user === roleModification.userId
      );

      const assignedRole = projectRoles.find(
        (role) => role.roleId === roleModification.roleId
      );

      changes.push({
        userName: `${userResource.firstName} ${userResource.lastName}`,
        roleName: assignedRole?.roleName || i18n.t('permissions.no_role'),
      });
    });

    return changes;
  }
);

export const selectSelectedUsersDataToAssignRoleTo = createSelector(
  [selectSelectedUsersToAssignRole, selectAllResources],
  (selectedUserIds, resources) =>
    selectedUserIds.map((userId) => {
      const selectedUserResource = resources.find(
        (resource) => resource.user === userId
      );

      return {
        userName: `${selectedUserResource?.firstName} ${selectedUserResource.lastName}`,
      };
    })
);
