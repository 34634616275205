import moment from 'moment';
import i18n from 'i18next';
import {
  sortByClockInAt,
  sortByProject,
  sortByRecordFirstName,
  sortByRecordLastName,
} from '../sort';
const ExcelJS = require('exceljs');

export const docName = (settings) => {
  const documentName = `${i18n.t('reportName.' + settings.reportName)}`;
  const documentFilename = `${i18n.t(
    'reportFilename.' + settings.reportName
  )}_${moment().format('YYYY-MM-DD')}`;
  return { documentName, documentFilename };
};

export const createReport = (reportData, settings) => {
  const { records, fromDate, toDate, projectName, resources, utcOffset } =
    reportData;

  const recordsWithFoundResource = records.filter((record) => record.resource);

  let offsetValue = utcOffset.split(':')[0];
  offsetValue = offsetValue.slice(1);
  offsetValue = parseInt(offsetValue);

  const tempFromDate = moment(fromDate).utc().add(offsetValue, 'hours');

  //-- Sort records by clockInAt and project and finally by resources
  recordsWithFoundResource.sort((a, b) => {
    sortByClockInAt(a, b, settings.language);
  });
  recordsWithFoundResource.sort((a, b) => {
    sortByProject(a, b, settings.language);
  });
  // if (settings.lastNameFirst)
  //   records.sort((a, b) => {
  //     sortByRecordLastName(a, b, settings.language);
  //   });

  recordsWithFoundResource.sort((a, b) => {
    sortByRecordFirstName(a, b, settings.language);
  });

  let _resources = {};
  let workDaysTotal = [];

  recordsWithFoundResource.forEach((record) => {
    const resource = record.resource;
    if (!(resource.user in _resources)) _resources[resource.user] = {};
    if (!(record.project in _resources[resource.user]))
      _resources[resource.user][record.project] = [];
    _resources[resource.user][record.project].push(record);

    const date = moment(record.clockInAt).format('YYYYMMDD');
    if (!workDaysTotal.includes(date)) {
      workDaysTotal.push(date);
    }
  });

  const mainReportHeader = () => {
    if (settings.filetype === 'excel') {
      const title = {
        richText: [{ text: projectName, font: { bold: true, size: 18 } }],
      };
      const date = {
        richText: [
          {
            text: moment().format('DD.MM.YYYY'),
            font: { bold: true },
          },
        ],
      };
      const dateRange = {
        richText: [
          {
            text: `${moment(tempFromDate).format('DD.MM.')}-${moment(
              toDate
            ).format('DD.MM.YYYY')}`,
            font: { bold: true },
          },
        ],
      };
      const workDaysText = {
        richText: [
          {
            text:
              workDaysTotal.length + ' ' + i18n.t('reports.work_days_short'),
            font: { bold: true, color: { argb: 'FFFF0000' } },
          },
        ],
      };
      let headerRows = [
        ['', title, '', '', '', '', '', '', ''],
        ['', dateRange, '', '', workDaysText, '', '', date, ''],
      ];
      return headerRows;
    } else {
      let headerRows = [
        {
          alignment: 'justify',
          columns: [
            { text: projectName, style: 'header' },
            { text: moment().format('DD.MM.YYYY'), bold: true },
          ],
        },
        {
          alignment: 'justify',
          columns: [
            {
              text: `${moment(tempFromDate).format('DD.MM.')}-${moment(
                toDate
              ).format('DD.MM.YYYY')}`,
              bold: true,
            },
            {
              text:
                workDaysTotal.length + ' ' + i18n.t('reports.work_days_short'),
              bold: true,
              color: 'red',
              alignment: 'left',
            },
          ],
        },
      ];
      return headerRows;
    }
  };

  const mainTableHeader = () => {
    let titles = [
      '',
      `${i18n.t('reports.name').toUpperCase()} / ${i18n.t('reports.site')}`,
      i18n.t('reports.sites'),
      i18n.t('reports.description'),
      i18n.t('reports.letter'),
      i18n.t('reports.work_days_short').toUpperCase(),
      i18n.t('reports.hours').toUpperCase(),
      i18n.t('reports.total'),
      i18n.t('reports.total_contracts') + ' €',
    ];

    if (settings.filetype === 'excel') {
      titles = titles.map((x, idx) => {
        if ([4, 5, 6].includes(idx)) {
          x = {
            richText: [
              {
                text: x,
                font: {
                  bold: true,
                  color: { argb: 'FFFF0000' },
                },
              },
            ],
          };
        } else {
          x = { richText: [{ text: x, font: { bold: true } }] };
        }
        return x;
      });
    } else {
      titles = titles.map((x, idx) => {
        if ([4, 5, 6].includes(idx)) {
          x = { text: x, style: 'tableHeaderRed' };
        } else {
          x = { text: x, style: 'tableHeader' };
        }
        return x;
      });
    }
    return titles;
  };

  const mainTableRows = () => {
    let rows = [];

    Object.keys(_resources).forEach((userKey, idx) => {
      try {
        let resourceRows = [];

        const resource = resources[userKey].resource;

        const projects = _resources[userKey];
        const number = idx + 1;

        let resourceName = `${resource.firstName} ${resource.lastName}`;
        if (settings.lastNameFirst) {
          resourceName = `${resource.lastName} ${resource.firstName}`;
        }

        let workDays = [];
        let totalHours = 0;

        Object.keys(projects).forEach((projectId) => {
          let projectHours = 0;
          projects[projectId].forEach((record, idx) => {
            const project = record.project;
            const clockIn = moment(record.clockInAt);
            const clockOut = moment(record.clockOutAt);
            const hours = clockOut.diff(clockIn, 'hours', true);
            totalHours += hours;
            projectHours += hours;
            let description = record.description;
            let assignmentNumber = '';
            if (record.assignment && record.assignment.id) {
              const assignment = record.assignment;
              assignmentNumber = !isNaN(assignment.name.charAt(0))
                ? assignment.name.split(' ')[0]
                : '';
              let assignmentName = !isNaN(assignment.name.charAt(0))
                ? assignment.name.split(' ').slice(1).join(' ')
                : assignment.name;
              description = `${assignmentName} ${record.description}`;
            }
            resourceRows.push([
              number,
              project.name,
              project.projectKey,
              description ? description : '',
              assignmentNumber,
              '',
              hours.toFixed(2).replace('.', ','),
              projects[projectId].length === idx + 1
                ? projectHours.toFixed(2).replace('.', ',')
                : '',
              '',
            ]);

            const date = clockIn.format('YYYYMMDD');
            if (!workDays.includes(date)) {
              workDays.push(date);
            }
          });
        });

        totalHours = totalHours.toFixed(2).replace('.', ',');

        let employeeRow = [
          number,
          resourceName,
          '',
          '',
          '',
          workDays.length,
          totalHours,
          '',
          '',
        ];
        if (settings.filetype === 'excel') {
          employeeRow = employeeRow.map((x) =>
            x
              ? {
                  richText: [
                    {
                      text: x,
                      font: {
                        bold: true,
                        color: { argb: 'FFFF0000' },
                      },
                    },
                  ],
                }
              : ''
          );
        } else {
          employeeRow = employeeRow.map((x) =>
            x ? { text: x, color: 'red', bold: true } : ''
          );
        }

        let totalRow = ['', '', '', '', '', '', '', totalHours, ''];

        resourceRows = [
          employeeRow,
          ...resourceRows,
          totalRow,
          ['', '', '', '', '', '', '', '', ''],
        ];
        rows = [...rows, ...resourceRows];
      } catch (error) {}
    });
    return rows;
  };

  const calcTableWidths = (tableheader) => {
    return tableheader.map(() => 'auto');
  };

  const fonts = {
    Helvetica: {
      normal: 'Helvetica',
      bold: 'Helvetica-Bold',
      italics: 'Helvetica-Oblique',
      bolditalics: 'Helvetica-BoldOblique',
    },
  };

  const mainHeader = mainReportHeader();
  console.log('mainheader', JSON.stringify(mainHeader));

  const tableHeader = mainTableHeader();
  console.log('tableheader', JSON.stringify(tableHeader));

  const tableWidths = calcTableWidths(mainTableHeader());
  console.log('tablewidth', JSON.stringify(tableWidths));

  const tableRows = mainTableRows();
  console.log('tablerows', JSON.stringify(tableRows));

  // *** Report section: Main Table ***

  let mainTable;
  if (settings.filetype === 'excel') {
    mainTable = [tableHeader, ...tableRows];
  } else {
    mainTable = {
      style: 'tableExample',
      color: '#444',
      table: {
        widths: tableWidths,
        headerRows: 1,
        body: [tableHeader, ...tableRows],
      },
    };
  }

  // *** Full report doc def ***
  const docDefinition = {
    pageOrientation: settings.pageOrientation,
    content: [...mainHeader, mainTable],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
      },
      subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      large: {
        fontSize: 16,
      },
      tableExample: {
        margin: [0, 0, 0, 0],
      },
      tableHeader: {
        bold: true,
        color: 'black',
      },
      tableHeaderRed: {
        bold: true,
        color: 'red',
      },
    },
    defaultStyle: {
      fontSize: 8,
      alignment: 'left',
      font: 'Helvetica',
      // alignment: 'justify'
    },
  };

  if (settings.filetype === 'excel') {
    console.log('Document', JSON.stringify(mainTable));

    const workbook = new ExcelJS.Workbook();
    let sheet = workbook.addWorksheet(
      `${i18n.t('reportName.' + settings.reportName)}`
    );
    sheet.addRows(mainTable);

    sheet.columns.forEach((column, colIdx) => {
      let dataMax = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        let len = cell.value.toString().length;
        if (Object.keys(cell.value).includes('richText')) {
          len = cell.value.richText[0].text.toString().length;
          if (cell.value.richText[0].font && cell.value.richText[0].font.bold) {
            len *= 1.5;
          }
        }
        dataMax = len > dataMax ? len : dataMax;

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        if ([4, 5, 6, 7].includes(colIdx)) {
          cell.alignment = {
            ...cell.alignment,
            horizontal: 'center',
          };
        }
      });
      column.width = dataMax;
    });

    sheet.insertRows(1, mainHeader);
    sheet.mergeCells('B1:D1');
    sheet.getColumn(1).alignment = 'right';
    sheet.getColumn(1).width = 2;

    return workbook.xlsx.writeBuffer().then((buffer) => {
      console.log(buffer.toString('base64'));
      return buffer.toString('base64');
    });
  } else {
    // console.log('Document', JSON.stringify(docDefinition));
    // const doc = printer.createPdfKitDocument(docDefinition);
    // const chunks = [];
    // doc.on('readable', () => {
    //   var chunk;
    //   while ((chunk = doc.read(9007199254740991)) !== null) {
    //     chunks.push(chunk);
    //   }
    // });
    // return new Promise(function (resolve) {
    //   doc.on('end', () => {
    //     const buffer = Buffer.concat(chunks);
    //     resolve(buffer.toString('base64'));
    //   });
    //   doc.end();
    // });
  }
};
